import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Auto-import required elements for Chart.js

const MonitoringSensor = () => {
    const { companyId, locationId, thingId } = useParams();
    const location = useLocation();
    const { thing } = location.state || {};  // Access the passed 'thing' data from the state

    const [readings, setReadings] = useState([]);  // State to store readings data
    const [loading, setLoading] = useState(true);  // For loading state
    const [error, setError] = useState(null);  // For error state
    const [showCharts, setShowCharts] = useState({
        velocity: true,
        acceleration: true,
        peak: true,
    }); // Control chart visibility

    const [chartColors, setChartColors] = useState({
        velocity: ['#ff6384', '#36a2eb', '#cc65fe'],
        acceleration: ['#ffce56', '#4bc0c0', '#9966ff'],
        peak: ['#ff9f40', '#ff6384', '#36a2eb']
    }); // Default chart colors

    // Function to fetch history data
    const fetchHistoryData = async () => {
        try {
            const access_token = sessionStorage.getItem('access_token'); // Retrieve token from sessionStorage
            if (!access_token) {
                setError('Access token not found. Please login.');
                setLoading(false);
                return;
            }

            const response = await axios.get(
                `https://iotinabox-api.mydevices.com/companies/${companyId}/locations/${locationId}/things/${thingId}/history?type=day`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`, // Pass the access token in the headers
                    },
                }
            );

            const data = response.data;
            console.log("API Response (History Data): ", data);

            if (data && data.readings) {
                const filteredReadings = data.readings.map(reading => ({
                    ...reading,
                    sensors: reading.sensors.filter(sensor =>
                        !['509', '510', '511', '512', '513', '514', '515', '516', '517', '518', '519', '520', '5', '100'].includes(sensor.channel)
                    )
                }));
                setReadings(filteredReadings);
            } else {
                setReadings([]);
            }

            setLoading(false);
        } catch (err) {
            console.error('Failed to fetch history data:', err);
            setError('Failed to fetch history data.');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHistoryData();
    }, [companyId, locationId, thingId]);

    // Prepare data for each chart
    const createChartData = (sensorKeys) => {
        const labels = readings.map(reading => new Date(reading.ts).toLocaleString());
        const datasets = sensorKeys.map((sensor, index) => {
            const data = readings.map(reading => {
                const sensorReading = reading.sensors.find(s => s.channel === sensor.channel);
                return sensorReading ? sensorReading.v : null;
            });

            // Add default fallback if chartColors[sensor.group] is undefined
            const borderColor = chartColors[sensor.group] ? chartColors[sensor.group][index] : '#000000';

            return {
                label: sensor.key,
                data,
                fill: false,
                borderColor,
                tension: 0.1
            };
        });

        return { labels, datasets };
    };

    const handleShowChart = (chartType) => {
        setShowCharts({ ...showCharts, [chartType]: !showCharts[chartType] });
    };

    const handleColorChange = (group, index, newColor) => {
        const newColors = [...chartColors[group]];
        newColors[index] = newColor;
        setChartColors({ ...chartColors, [group]: newColors });
    };

    const sensorGroups = {
        velocity: [
            { key: "Velocity RMS X-axis", channel: "500", group: 'velocity' },
            { key: "Velocity RMS Y-axis", channel: "501", group: 'velocity' },
            { key: "Velocity RMS Z-axis", channel: "502", group: 'velocity' },
        ],
        acceleration: [
            { key: "Acceleration RMS X-axis", channel: "506", group: 'acceleration' },
            { key: "Acceleration RMS Y-axis", channel: "507", group: 'acceleration' },
            { key: "Acceleration RMS Z-axis", channel: "508", group: 'acceleration' },
        ],
        peak: [
            { key: "Peak X-axis", channel: "503", group: 'peak' },
            { key: "Peak Y-axis", channel: "504", group: 'peak' },
            { key: "Peak Z-axis", channel: "505", group: 'peak' },
        ]
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="container mt-5">
            <center><img src="https://contromote.com/wp-content/uploads/2023/12/Contromote_Logo_800.png"></img></center>
            <h2 className="text-center" style={{ marginTop: '20px' }}>Preventive Maintenance</h2>

            {thing && (
                <div className="row mt-4">
                    <div className="col-12">
                        <h3 className="text-center">Thing Details</h3>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <ul className="list-unstyled">
                            <li><strong>Thing Name:</strong> {thing.thing_name}</li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <ul className="list-unstyled">
                            <li><strong>Thing Type:</strong> {thing.thing_type}</li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <ul className="list-unstyled">
                            <li><strong>Sensor Type:</strong> {thing.sensor_type}</li>
                        </ul>
                    </div>
                </div>
            )}


            {
                readings.length > 0 ? (
                    <div>
                        <h3>Sensor Readings (Day):</h3>

                        {/* Individual Surface Temperature Chart */}
                        <div>
                            <h4>Surface Temperature</h4>
                            <Line data={createChartData([{ key: "Surface Temperature", channel: "3", group: '' }])} />
                        </div>

                        {/* Velocity RMS */}
                        <div>
                            <button
                                className="btn btn-primary btn-block mt-2"
                                onClick={() => handleShowChart('velocity')}
                            >
                                {showCharts.velocity ? 'Hide' : 'Show'} Velocity RMS Charts
                            </button>

                            {showCharts.velocity && (
                                <>
                                    {sensorGroups.velocity.map((sensor, index) => (
                                        <div key={index}>
                                            <label>
                                                Change Color:
                                                <input
                                                    type="color"
                                                    value={chartColors.velocity[index]}
                                                    onChange={(e) => handleColorChange('velocity', index, e.target.value)}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                    <Line data={createChartData(sensorGroups.velocity)} />
                                </>
                            )}
                        </div>

                        {/* Acceleration RMS */}
                        <div>
                            <button className="btn btn-primary btn-block mt-2" onClick={() => handleShowChart('acceleration')}>
                                {showCharts.acceleration ? 'Hide' : 'Show'} Acceleration RMS Charts
                            </button>
                            {showCharts.acceleration && (
                                <>
                                    {sensorGroups.acceleration.map((sensor, index) => (
                                        <div key={index}>
                                            <label>
                                                Change Color:
                                                <input
                                                    type="color"
                                                    value={chartColors.acceleration[index]}
                                                    onChange={(e) => handleColorChange('acceleration', index, e.target.value)}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                    <Line data={createChartData(sensorGroups.acceleration)} />
                                </>
                            )}
                        </div>

                        {/* Peak */}
                        <div>
                            <button className="btn btn-primary btn-block mt-2" onClick={() => handleShowChart('peak')}>
                                {showCharts.peak ? 'Hide' : 'Show'} Peak Charts
                            </button>
                            {showCharts.peak && (
                                <>
                                    {sensorGroups.peak.map((sensor, index) => (
                                        <div key={index}>
                                            <label>
                                                Change Color:
                                                <input
                                                    type="color"
                                                    value={chartColors.peak[index]}
                                                    onChange={(e) => handleColorChange('peak', index, e.target.value)}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                    <Line data={createChartData(sensorGroups.peak)} />
                                </>
                            )}
                        </div>

                    </div>
                ) : (
                    <p>No readings available</p>
                )
            }
        </div >
    );
};

export default MonitoringSensor;
