import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [statusCode, setStatusCode] = useState(null);
    const navigate = useNavigate();

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        const access_token = sessionStorage.getItem('access_token');

        if (access_token) {
            try {
                const response = await fetch('https://iotinabox-api.mydevices.com/users/password/forgot', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + access_token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        client_id: 'conciel',
                        email: email,
                    }),
                });

                const result = await response.json();
                setStatusCode(response.status);

                if (response.status === 200) {
                    alert('Password reset request sent successfully.');
                } else {
                    alert('Error: ' + result.message);
                }
            } catch (error) {
                console.error('Error:', error);
                alert('Failed to send password reset request.');
            }
        } else {
            alert('Access token not found. Please login.');
            navigate('/');
        }
    };

    const handleLoginRedirect = () => {
        navigate('/'); // Navigate to login page
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Forgot Password</h2>
            <form onSubmit={handleForgotPassword} className="w-50 mx-auto">
                <div className="form-group mb-3">
                    <label htmlFor="email">Email address</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                <button
                    type="button"  // Change type to button
                    className="btn btn-secondary btn-block mt-2"
                    onClick={handleLoginRedirect}  // Handle the login navigation
                >
                    Login
                </button>
            </form>
            {statusCode && (
                <div className="alert alert-info mt-4 text-center">
                    Status Code: {statusCode}
                </div>
            )}
        </div>
    );
};

export default ForgotPassword;
