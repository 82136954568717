import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ThingDetail = () => {
    const { companyId, locationId, thingId, token } = useParams(); // Get the token from URL params
    const [readings, setReadings] = useState([]);  // For storing all readings
    const [loading, setLoading] = useState(true);  // For loading state
    const [error, setError] = useState(null);  // For error state
    const navigate = useNavigate();  // For navigation

    // API call to fetch history data
    const fetchHistoryData = async () => {
        try {
            const access_token = sessionStorage.getItem('access_token'); // Retrieve token from sessionStorage
            if (!access_token) {
                setError('Access token not found. Please login.');
                setLoading(false);
                return;
            }

            const response = await axios.get(
                `https://iotinabox-api.mydevices.com/companies/${companyId}/locations/${locationId}/things/${thingId}/history?type=day`,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`, // Pass the access token in the headers
                    },
                }
            );

            const data = response.data;
            console.log("API Response: ", data);  // Debugging: log the response

            // Check if the 'readings' array exists
            if (data && data.readings) {
                setReadings(data.readings);  // Set readings array if it exists
            } else {
                setReadings([]);  // Set readings to empty if it's not found
            }

            setLoading(false);  // Mark loading as complete
        } catch (err) {
            console.error('Failed to fetch history data:', err);  // Debugging: log the error
            setError('Failed to fetch history data. Please try again.');
            setLoading(false);  // Ensure loading state is false
        }
    };

    useEffect(() => {
        fetchHistoryData();

        // Check if the thingId matches the first specific value
        if (thingId === '97bb4490-6c84-11ef-8193-9103085b147a') {
            // Redirect to PreventiveMaintenance.js with params
            navigate(`/preventive-maintenance/${companyId}/locations/${locationId}/things/${thingId}/token/${token}`, {
                state: { readings },  // Pass the 'readings' array as state
            });
        }
        // Check if the thingId matches the second specific value
        else if (thingId === 'e6f9d3f0-6c84-11ef-8dfa-e941a9dbbcd3') {
            // Redirect to MonitoringSystem.js with params
            navigate(`/monitoring-system/${companyId}/locations/${locationId}/things/${thingId}/token/${token}`, {
                state: { readings },  // Pass the 'readings' array as state
            });
        }
    }, [companyId, locationId, thingId, token, readings, navigate]);


    // If data is still loading
    if (loading) return <p>Loading...</p>;

    // If there was an error fetching data
    if (error) return <p>{error}</p>;

    return (
        <div className="container mt-5">
            <h2>Thing Detail</h2>
            <p>Company ID: {companyId}</p>
            <p>Location ID: {locationId}</p>
            <p>Thing ID: {thingId}</p>

            {/* Display the readings array */}
            {readings.length > 0 ? (
                <div>
                    <h3>Readings (Day):</h3>
                    <ul>
                        {readings.map((reading, index) => (
                            <li key={index}>
                                {/* Display the timestamp */}
                                <strong>Timestamp:</strong> {new Date(reading.ts).toLocaleString()} <br />
                                {/* Loop through sensors */}
                                <strong>Sensors:</strong>
                                <ul>
                                    {reading.sensors.map((sensor, sensorIndex) => (
                                        <li key={sensorIndex}>
                                            Channel: {sensor.channel}, Value: {sensor.v}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>No readings available</p>
            )}
        </div>
    );
};

export default ThingDetail;
