import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import ForgotPassword from './components/ForgotPassword';
import Dashboard from './Dashboard';
import ThingDetail from './components/ThingDetails';
import PreventiveMaintenance from './components/PreventiveMaintenance';  // Update this path as necessary
import MonitoringSensor from './components/MonitoringSensor';  // Import the MonitoringSystem component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/companies/:companyId/locations/:locationId/things/:thingId/token/:token"
          element={<ThingDetail />}
        />
        <Route
          path="/preventive-maintenance/:companyId/locations/:locationId/things/:thingId/token/:token"
          element={<PreventiveMaintenance />}
        />
        <Route
          path="/monitoring-system/:companyId/locations/:locationId/things/:thingId/token/:token"
          element={<MonitoringSensor />}
        />
      </Routes>
    </Router>
  );
}

export default App;
