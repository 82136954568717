import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ onTokenReceived }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://iotinabox-api.mydevices.com/v1.0/oauth/token', {
                grant_type: 'password',
                client_id: 'conciel',
                client_secret: '89bf4f64-232d-487b-8bd5-132691dda8a5',
                username,
                password,
            });
            const receivedToken = response.data.access_token;
            sessionStorage.setItem('access_token', receivedToken);

            if (onTokenReceived) {
                onTokenReceived(receivedToken); // Optional callback
            }

            navigate('/dashboard'); // Redirect to dashboard after successful login
        } catch (error) {
            console.error('Error during authentication', error);
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-4">Login</h1>
            <form onSubmit={handleLogin} className="w-50 mx-auto">
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                    Login
                </button>
            </form>
        </div>
    );
};

export default Login;
