import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [companiesData, setCompaniesData] = useState([]);
    const [locationsData, setLocationsData] = useState([]);
    const [thingsData, setThingsData] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [selectedLocationId, setSelectedLocationId] = useState(null);
    const navigate = useNavigate();

    const baseApiUrl = 'https://iotinabox-api.mydevices.com';

    const fetchCompanies = async (access_token) => {
        const response = await fetch(`${baseApiUrl}/companies`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setCompaniesData(data);
        } else {
            console.error('Failed to fetch companies');
        }
    };

    const fetchLocationsByCompanyId = async (access_token, companyId) => {
        const response = await fetch(`${baseApiUrl}/companies/${companyId}/locations`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setLocationsData(data);
        } else {
            console.error('Failed to fetch locations');
        }
    };

    const fetchThingsByLocation = async (access_token, companyId, locationId) => {
        const response = await fetch(`${baseApiUrl}/companies/${companyId}/locations/${locationId}/things`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            setThingsData(data); // Set the things data
        } else {
            console.error('Failed to fetch things');
        }
    };

    // Fetch companies when the component mounts
    useEffect(() => {
        const access_token = sessionStorage.getItem('access_token');
        if (!access_token) {
            alert('Access token not found. Please login.');
            navigate('/login');
        } else {
            fetchCompanies(access_token);
        }
    }, [navigate]);

    // Fetch locations when a company is selected
    useEffect(() => {
        if (selectedCompanyId) {
            const access_token = sessionStorage.getItem('access_token');
            fetchLocationsByCompanyId(access_token, selectedCompanyId);
        }
    }, [selectedCompanyId]);

    // Fetch things when a location is selected
    useEffect(() => {
        if (selectedCompanyId && selectedLocationId) {
            const access_token = sessionStorage.getItem('access_token');
            fetchThingsByLocation(access_token, selectedCompanyId, selectedLocationId);
        }
    }, [selectedCompanyId, selectedLocationId]);

    // Function to handle clicking on a "thing" and navigating based on thingId
    const handleThingClick = (thing) => {
        const access_token = sessionStorage.getItem('access_token');

        // Check if the thingId matches specific values to determine where to navigate
        if (thing.id === '97bb4490-6c84-11ef-8193-9103085b147a') {
            // Navigate to PreventiveMaintenance with state
            navigate(`/preventive-maintenance/${selectedCompanyId}/locations/${selectedLocationId}/things/${thing.id}/token/${access_token}`, {
                state: { thing },  // Pass the entire thing object as state
            });
        }
        else if (thing.id === 'e6f9d3f0-6c84-11ef-8dfa-e941a9dbbcd3') {
            // Navigate to MonitoringSystem with state
            navigate(`/monitoring-system/${selectedCompanyId}/locations/${selectedLocationId}/things/${thing.id}/token/${access_token}`, {
                state: { thing },  // Pass the entire thing object as state
            });
        }
    };


    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Dashboard</h2>

            {/* Select Company */}
            <div className="form-group mb-4">
                <label htmlFor="companySelect">Select Company</label>
                <select
                    id="companySelect"
                    className="form-control"
                    value={selectedCompanyId || ''}
                    onChange={(e) => setSelectedCompanyId(e.target.value)}
                >
                    <option value="">Select a company</option>
                    {companiesData.map((company) => (
                        <option key={company.id} value={company.id}>
                            {company.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Select Location */}
            {locationsData.length > 0 && (
                <div className="form-group mb-4">
                    <label htmlFor="locationSelect">Select Location</label>
                    <select
                        id="locationSelect"
                        className="form-control"
                        value={selectedLocationId || ''}
                        onChange={(e) => setSelectedLocationId(e.target.value)}
                    >
                        <option value="">Select a location</option>
                        {locationsData.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.name}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {/* Display Things */}
            {thingsData.length > 0 && (
                <div>
                    <h3>Things Data:</h3>
                    <ul>
                        {thingsData.map((thing) => (
                            <li
                                key={thing.id}
                                onClick={() => handleThingClick(thing)} // Pass the entire thing object
                                style={{ cursor: 'pointer', textDecoration: 'underline' }} // Styling for clickable items
                            >
                                {thing.thing_name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
